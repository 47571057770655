import React from "react"
import { Icon, VerticalAligned, Input, InputProps } from "@opensea/ui-kit"
import {
  LazyTraitSelector,
  SelectedTrait,
} from "@/components/traits/TraitSelector"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  collection?: string
  onSelectTrait?: (trait: SelectedTrait) => unknown
} & Pick<InputProps, "onEnter">

export const AssetSearchTraitAndQueryInput = ({
  collection,
  onEnter,
  onSelectTrait,
}: Props) => {
  const t = useTranslate("components")

  return collection && onSelectTrait ? (
    <LazyTraitSelector
      collectionSlug={collection}
      overrides={{
        Input: {
          placeholder: t("search.input.placeholder", "Search by name or trait"),
          startEnhancer: (
            <VerticalAligned className="mr-2">
              <Icon className="text-secondary" size={24} value="search" />
            </VerticalAligned>
          ),
          clearable: true,
        },
        List: {
          height: "350px",
        },
      }}
      variant="input-only"
      onEnter={onEnter}
      onSelectTrait={onSelectTrait}
    />
  ) : (
    <Block flexGrow={1}>
      <Input
        clearable
        placeholder={t(
          "assetSearchCollectionView.searchByName",
          "Search by name",
        )}
        startEnhancer={
          <VerticalAligned className="mr-2">
            <Icon className="text-secondary" size={24} value="search" />
          </VerticalAligned>
        }
        onEnter={onEnter}
      />
    </Block>
  )
}
