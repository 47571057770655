/**
 * @generated SignedSource<<beed1fad5407bdfb835f7d31344caf33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchPills_data$data = {
  readonly selectedCollections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly imageUrl: string | null;
        readonly name: string;
        readonly slug: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "SearchPills_data";
};
export type SearchPills_data$key = {
  readonly " $data"?: SearchPills_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchPills_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collections"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchPills_data",
  "selections": [
    {
      "alias": "selectedCollections",
      "args": [
        {
          "kind": "Variable",
          "name": "collections",
          "variableName": "collections"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 25
        },
        {
          "kind": "Literal",
          "name": "includeHidden",
          "value": true
        }
      ],
      "concreteType": "CollectionTypeConnection",
      "kind": "LinkedField",
      "name": "collections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0004c6d63c5bbe514cfe22b3f898b140";

export default node;
