import React, { MouseEventHandler, MouseEvent } from "react"
import { Breakpoint } from "@opensea/ui-kit"
import { RenderComponentProps } from "masonic"
import styled, { css } from "styled-components"
import { LinkProps } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { media } from "@/styles/styleUtils"
import { FullRowContainer } from "../styles"
import { CollapsedRow } from "./CollapsedRow.react"

export type FeatureTableRenderProps<T> = RenderComponentProps<T> & {
  className?: string
  fullRowMinBreakpoint?: Breakpoint
}
export type FeatureTableRenderPrimaryProps<T> = FeatureTableRenderProps<T> & {
  isCollapsed: boolean
  toggleCollapsed: () => void
  ShowMoreButton: React.ComponentType
}

export type FeatureTableRowProps<T> = FeatureTableRenderProps<T> &
  Partial<Pick<LinkProps, "eventParams">> & {
    renderPrimary: React.ComponentType<FeatureTableRenderPrimaryProps<T>>
    renderMore: React.ComponentType<FeatureTableRenderProps<T>>
    renderFullRow?: React.ComponentType<FeatureTableRenderProps<T>>
    shouldUseFullRow: boolean | undefined
    role?: string
    getHref?: (renderProps: FeatureTableRenderProps<T>) => string | undefined
    onClick?: (
      renderProps: FeatureTableRenderProps<T>,
      event: MouseEvent<HTMLElement>,
    ) => unknown
    showBorder?: boolean
    showInteractiveStyles?: boolean
  }

export const FeatureTableRow = <T,>({
  renderPrimary,
  renderMore,
  renderFullRow: FullRow,
  shouldUseFullRow,
  getHref,
  fullRowMinBreakpoint = "xl",
  onClick,
  showBorder = true,
  showInteractiveStyles = false,
  ...renderProps
}: FeatureTableRowProps<T>) => {
  const href = getHref?.(renderProps)
  const handleClick: MouseEventHandler<HTMLElement> | undefined = onClick
    ? e => onClick(renderProps, e)
    : undefined

  const renderCollapsedRow = () => {
    return (
      <RowBreakpoint $fullRowMinBreakpoint={fullRowMinBreakpoint}>
        <CollapsedRow
          eventParams={{ index: renderProps.index }}
          href={href}
          renderMore={renderMore}
          renderPrimary={renderPrimary}
          onClick={handleClick}
          {...renderProps}
        />
      </RowBreakpoint>
    )
  }

  const renderFullRow = () => {
    return (
      <FullRowBreakpoint $fullRowMinBreakpoint={fullRowMinBreakpoint}>
        {FullRow ? (
          <FullRowContainer
            $showBorder={showBorder}
            eventParams={{ index: renderProps.index }}
            href={href ?? ""}
            showInteractiveStyles={showInteractiveStyles}
            onClick={handleClick}
          >
            <FullRow {...renderProps} />
          </FullRowContainer>
        ) : (
          <CollapsedRow
            eventParams={{ index: renderProps.index }}
            href={href}
            renderMore={renderMore}
            renderPrimary={renderPrimary}
            onClick={handleClick}
            {...renderProps}
          />
        )}
      </FullRowBreakpoint>
    )
  }

  // Render both initially when shouldUseFullRow is undefined (SSR).
  // One will be hidden by CSS and rendering both prevents layout shift.
  if (shouldUseFullRow === undefined) {
    return (
      <>
        {renderFullRow()}
        {renderCollapsedRow()}
      </>
    )
  }

  return <>{shouldUseFullRow ? renderFullRow() : renderCollapsedRow()}</>
}

type RowProps = {
  $fullRowMinBreakpoint: Breakpoint
}

const FullRowBreakpoint = styled(Block)<RowProps>`
  display: none;
  ${props =>
    media({
      [props.$fullRowMinBreakpoint]: css`
        display: block;
      `,
    })}
`

const RowBreakpoint = styled(Block)<RowProps>`
  ${props =>
    media({
      [props.$fullRowMinBreakpoint]: css`
        display: none;
      `,
    })}
`
