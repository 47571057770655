import React, { useEffect, useState } from "react"
import { Flex, Media, Text } from "@opensea/ui-kit"
import { get } from "lodash"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { useGetStatusItemLabel } from "@/components/search/assets/AssetSearchFilter"
import { useEventFilters } from "@/components/search/featured-filter"
import {
  useGetOrderStatusItemLabel,
  useGetOfferTypeItemLabel,
} from "@/components/search/orders/utils"
import { Button } from "@/design-system/Button"
import { Pill, PILL_SIZES } from "@/design-system/Pill"
import { useCategoriesInfo } from "@/hooks/useCategoriesInfo"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { Search } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import {
  SearchToggle,
  TraitRangeType,
  PriceFilterType,
  TraitInputType,
} from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import {
  OfferTypeToggle,
  OrderStatusToggle,
} from "@/lib/graphql/__generated__/AccountOffersOrderSearchListQuery.graphql"
import { RarityFilterType } from "@/lib/graphql/__generated__/CollectionAssetSearchListQuery.graphql"
import { EventType } from "@/lib/graphql/__generated__/ItemType.graphql"
import { SearchPills_data$key } from "@/lib/graphql/__generated__/SearchPills_data.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { flatMap } from "@/lib/helpers/array"
import { Promiseable } from "@/lib/helpers/promise"
import {
  checkAndReplace,
  EN_DASH,
  snakeCaseToSentenceCase,
} from "@/lib/helpers/stringUtils"

type SearchPillsProps = {
  dataKey?: SearchPills_data$key | null
  queryFilter?: string
  categoryFilter?: ReadonlyArray<string>
  chainFilter?: ReadonlyArray<ChainIdentifier>
  collectionFilter?: ReadonlyArray<string>
  eventFilter?: ReadonlyArray<EventType>
  featuredFilter?: ReadonlyArray<SearchToggle>
  numericTraitFilter?: ReadonlyArray<TraitRangeType>
  offerTypeFilter?: ReadonlyArray<OfferTypeToggle>
  orderStatusFilter?: ReadonlyArray<OrderStatusToggle>
  paymentFilter?: ReadonlyArray<string>
  priceFilter?: PriceFilterType
  rarityFilter?: RarityFilterType
  isAutoHiddenFilter?: boolean
  stringTraitFilter?: ReadonlyArray<TraitInputType>
  filterOutListingsWithoutRequestedCreatorFees?: boolean
  clear: () => void
  update: (searchState: Partial<Search>) => Promiseable<unknown>
}

type SearchPill = {
  text: React.ReactNode
  alt?: string
  imageUrl?: string
  onClick(): void
}

type CollectionInfo = {
  imageUrl: string | null
  name: string
  slug: string
}

export const SearchPills = ({
  queryFilter,
  categoryFilter,
  chainFilter,
  collectionFilter,
  eventFilter,
  featuredFilter,
  numericTraitFilter,
  offerTypeFilter,
  orderStatusFilter,
  paymentFilter,
  priceFilter,
  rarityFilter,
  isAutoHiddenFilter,
  stringTraitFilter,
  filterOutListingsWithoutRequestedCreatorFees,
  clear,
  update,
  dataKey,
}: SearchPillsProps) => {
  const t = useTranslate("phoenix")
  const minText = t("search.min", "Min")
  const maxText = t("search.max", "Max")
  const data = useFragment(
    graphql`
      fragment SearchPills_data on Query
      @argumentDefinitions(collections: { type: "[CollectionSlug!]" }) {
        selectedCollections: collections(
          first: 25
          collections: $collections
          includeHidden: true
        ) {
          edges {
            node {
              imageUrl
              name
              slug
            }
          }
        }
      }
    `,
    dataKey ?? null,
  )
  const selectedCollections = data?.selectedCollections

  const { getChainName } = useChains()
  const getStatusItemLabel = useGetStatusItemLabel()
  const getOrderStatusItemLabel = useGetOrderStatusItemLabel()
  const getOfferTypeItemLabel = useGetOfferTypeItemLabel()
  const eventFilterItems = useEventFilters()
  const [cachedCollectionInfo, setCachedCollectionInfo] = useState<
    Record<string, CollectionInfo | undefined>
  >({})

  const collectionItems = flatMap(collectionFilter || [], slug => {
    const collection = cachedCollectionInfo[slug]

    if (!collection) {
      return [
        {
          label: slug,
          slug,
          ariaLabel: slug,
        },
      ]
    }

    const { imageUrl, name } = collection

    return [
      {
        imageUrl: imageUrl || undefined,
        label: name,
        slug,
        ariaLabel: name,
      },
    ]
  })

  const CATEGORIES = useCategoriesInfo()
  useEffect(() => {
    setCachedCollectionInfo(oldCachedCollectionInfo => ({
      ...oldCachedCollectionInfo,
      ...getNodes(selectedCollections).reduce(
        (res, collection) => ({
          ...res,
          [collection.slug]: collection,
        }),
        {},
      ),
    }))
  }, [selectedCollections])

  let searchPills: SearchPill[] = [
    ...(filterOutListingsWithoutRequestedCreatorFees
      ? [
          {
            text: (
              <PillText>
                {t(
                  "search.listingFeesSupportsCreatorPill",
                  "Creator earnings: Listings support creator",
                )}{" "}
              </PillText>
            ),
            onClick: () =>
              update({
                filterOutListingsWithoutRequestedCreatorFees: undefined,
              }),
          },
        ]
      : []),

    ...getQueryPill(query => update({ query }), queryFilter),
    ...getSearchPills(
      featuredFilter =>
        update({
          toggles: featuredFilter?.length ? featuredFilter : undefined,
        }),
      featuredFilter => getStatusItemLabel(featuredFilter),
      featuredFilter,
    ),
    ...getSearchPills(
      offerTypeFilter =>
        update({
          offerTypeToggles: offerTypeFilter?.length
            ? offerTypeFilter
            : undefined,
        }),
      offerTypeFilter => getOfferTypeItemLabel(offerTypeFilter),
      offerTypeFilter,
    ),
    ...getSearchPills(
      orderStatusFilter =>
        update({
          orderStatusToggles: orderStatusFilter?.length
            ? orderStatusFilter
            : undefined,
        }),
      orderStatusFilter => getOrderStatusItemLabel(orderStatusFilter),
      orderStatusFilter,
    ),
    ...getSearchPills(
      paymentFilter =>
        update({
          paymentAssets: paymentFilter?.length ? paymentFilter : undefined,
        }),
      paymentFilter => paymentFilter,
      paymentFilter,
    ),
    ...getStringTraitFilterSearchPills(
      stringTraitFilter =>
        update({
          stringTraits: stringTraitFilter?.length
            ? stringTraitFilter
            : undefined,
        }),
      stringTraitFilter,
    ),
    ...getSearchPills(
      chainFilter =>
        update({ chains: chainFilter?.length ? chainFilter : undefined }),
      chainFilter => getChainName(chainFilter),
      chainFilter,
    ),
    ...getSearchPills(
      eventFilter =>
        update({ eventTypes: eventFilter?.length ? eventFilter : undefined }),
      eventFilter => {
        const item = eventFilterItems.find(item => item.filter === eventFilter)
        return item ? item.label : snakeCaseToSentenceCase(eventFilter)
      },
      eventFilter,
    ),
    ...getSearchPills(
      categoryFilter =>
        update({
          categories: categoryFilter?.length ? categoryFilter : undefined,
        }),
      categoryFilter => {
        const item = CATEGORIES.find(
          category => category.slug === categoryFilter,
        )
        return item ? item.name : snakeCaseToSentenceCase(categoryFilter)
      },
      categoryFilter,
    ),
    ...getSearchPills(
      _collectionFilter =>
        update({
          collections: _collectionFilter
            ? _collectionFilter.map(
                collectionFilterItem => collectionFilterItem.slug,
              )
            : undefined,
        }),
      collectionFilter => collectionFilter.label,
      collectionItems,
    ),
    ...getSearchPills(
      numericTraitFilter =>
        update({
          numericTraits: numericTraitFilter?.length
            ? numericTraitFilter
            : undefined,
        }),
      numericTraitFilter =>
        `${numericTraitFilter.name}: ${numericTraitFilter.ranges[0].min} to ${numericTraitFilter.ranges[0].max}`,
      numericTraitFilter,
    ),
    ...getIsAutoHiddenPill(
      isAutoHiddenFilter
        ? t("search.autoHidden", "Auto-hidden")
        : t("search.manuallyHidden", "Hidden by you"),
      isAutoHiddenFilter => update({ isAutoHidden: isAutoHiddenFilter }),
      isAutoHiddenFilter,
    ),
  ]

  if (priceFilter) {
    if (priceFilter.min || priceFilter.max) {
      searchPills = [
        ...searchPills,
        ...getSearchPills(
          priceFilter =>
            update({
              priceFilter:
                priceFilter && priceFilter[0] ? priceFilter[0] : undefined,
            }),
          priceFilter => {
            if (priceFilter.min && priceFilter.max) {
              return `${priceFilter.min}${EN_DASH}${priceFilter.max} ${priceFilter.symbol}`
            }
            if (priceFilter.min) {
              return `>${priceFilter.min} ${priceFilter.symbol}`
            }
            return `<${priceFilter.max} ${priceFilter.symbol}`
          },
          [priceFilter],
        ),
      ]
    }
  }

  if (rarityFilter) {
    if (rarityFilter.min || rarityFilter.max) {
      searchPills = [
        ...searchPills,
        ...getSearchPills(
          rarityFilter =>
            update({
              rarityFilter: rarityFilter?.[0] ? rarityFilter[0] : undefined,
            }),
          rarityFilter => {
            const fieldDisplayText = t(
              "search.rarityFilter.field",
              "rarity {{field}}",
              {
                field: checkAndReplace(
                  rarityFilter.field.toLowerCase(),
                  "_",
                  " ",
                ),
              },
              { forceString: true },
            )
            const rarityMinText = rarityFilter.min
              ? `${minText} ${fieldDisplayText} ${rarityFilter.min}`
              : ""
            const rarityMaxText = rarityFilter.max
              ? `${maxText} ${fieldDisplayText} ${rarityFilter.max}`
              : ""
            const separatorText = rarityMinText && rarityMaxText ? ", " : ""
            return `${rarityMinText}${separatorText}${rarityMaxText}`
          },
          [rarityFilter],
        ),
      ]
    }
  }

  if (!searchPills.length) {
    return null
  }

  return (
    <Media greaterThanOrEqual="lg">
      <Flex
        asChild
        className="m-0 mb-3 flex-wrap gap-2"
        data-testid="search-pills"
      >
        <ul>
          {searchPills.map(({ imageUrl, text, onClick }, idx) => {
            return (
              <Flex asChild data-testid="Pill" key={idx}>
                <li>
                  <Pill
                    imageUrl={imageUrl}
                    overrides={{ Icon: { className: "font-semibold" } }}
                    text={text}
                    variant={PILL_SIZES.TINY}
                    onClose={onClick}
                  />
                </li>
              </Flex>
            )
          })}
          {searchPills.length > 1 && (
            <Flex asChild key="clear-all">
              <li>
                <ClearAllButton
                  onClick={() => {
                    clear()
                  }}
                >
                  {t("search.clearAllCTA", "Clear all")}
                </ClearAllButton>
              </li>
            </Flex>
          )}
        </ul>
      </Flex>
    </Media>
  )
}

const ClearAllButton = styled(Button).attrs({
  variant: "secondary",
})`
  height: 40px;
  border-radius: 12px;
`

const PillText = styled(Text.Body).attrs({
  size: "small",
  weight: "semibold",
})`
  white-space: nowrap;
`

const getStringTraitFilterSearchPills = (
  setStringTraitFilters: (
    stringTraitFilters?: ReadonlyArray<TraitInputType>,
  ) => unknown,
  stringTraitFilters?: ReadonlyArray<TraitInputType>,
) => {
  if (!stringTraitFilters) {
    return []
  }

  // Loop over all trait filters and spit out a string for each one.
  return stringTraitFilters
    .map(stringTraitFilter => {
      return stringTraitFilter.values.map(s => {
        const text = (
          <PillText>
            <PillText className="text-secondary">
              {snakeCaseToSentenceCase(stringTraitFilter.name)}
              {": "}
            </PillText>
            {snakeCaseToSentenceCase(s)}
          </PillText>
        )
        return {
          text,
          onClick: () => {
            // Remove just this name / value combination.
            const newValues = stringTraitFilter.values.filter(v => v !== s)
            const newFilters = stringTraitFilters.filter(
              st => st !== stringTraitFilter,
            )

            // still any trait values remaining
            if (newValues.length) {
              newFilters.push({
                name: stringTraitFilter.name,
                values: newValues,
              })
            }

            setStringTraitFilters(newFilters)
          },
        }
      })
    })
    .flat()
}

const getSearchPills = <T,>(
  setFilters: (filters?: ReadonlyArray<T>) => unknown,
  getDisplayText: (filter: T) => string,
  filters?: ReadonlyArray<T>,
) => {
  if (!filters) {
    return []
  }

  return filters.map(item => {
    return {
      imageUrl: get(item, "imageUrl"),
      text: <PillText>{getDisplayText(item)}</PillText>,
      alt: getDisplayText(item),
      onClick: () => setFilters(filters.filter(i => i !== item)),
    }
  })
}

const getIsAutoHiddenPill = (
  label: string,
  setIsAutoHidden: (isAutoHidden?: boolean) => unknown,
  isAutoHidden?: boolean,
) => {
  if (isAutoHidden === undefined) {
    return []
  }
  return [
    {
      text: <PillText>{label}</PillText>,
      onClick: () => setIsAutoHidden(undefined),
    },
  ]
}

const getQueryPill = (
  setQuery: (query?: string) => unknown,
  query?: string,
) => {
  if (!query) {
    return []
  }
  return [
    {
      text: <PillText>{query}</PillText>,
      onClick: () => setQuery(undefined),
    },
  ]
}
