import React, {
  AriaRole,
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
} from "react"
import {
  CenterAligned,
  CenterAlignedProps,
  SpaceBetween,
  Text,
  TextBodyProps,
  classNames,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Item, ItemProps } from "@/design-system/Item"

export const hoverStyles = css`
  :hover {
    box-shadow: none;
    background-color: ${props =>
      props.theme.colors.components.background.gray1};
  }
`
export const pressedStyles = css`
  :active {
    transition: background-color 0.25s ease-in-out;
    background-color: ${props =>
      props.theme.colors.components.background.gray2};
  }
`
type FullRowProps = ItemProps & {
  showInteractiveStyles?: boolean
  $showBorder?: boolean
}

export const FullRowContainer = styled(Item).attrs(props => ({
  role: "row" as AriaRole,
  className: classNames("border border-level-2 p-4", props.className),
}))<FullRowProps>`
  position: relative;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: ${props => (props.$showBorder ? "16px" : "12px 0px")};
  border: ${props => !props.$showBorder && "none"};
  border-radius: ${props => props.theme.borderRadius.medium};
  justify-content: space-between;
  ${props =>
    props.showInteractiveStyles &&
    css`
      && {
        cursor: pointer;
        ${hoverStyles};
        ${pressedStyles};
      }
    `};
`

export const HeaderContainer = forwardRef<
  ElementRef<typeof SpaceBetween>,
  ComponentPropsWithoutRef<typeof SpaceBetween>
>(({ className, ...props }, ref) => {
  return (
    <SpaceBetween
      className={classNames(
        "sticky z-[1] border-b border-b-level-2 bg-base-1 p-4 text-secondary",
        // Box shadow applied to hide the hover shadow underlayed items.
        "shadow-[-8px_0px_0px_0px_theme(colors.base-1),_8px_0px_0px_0px_theme(colors.base-1)]",
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})

HeaderContainer.displayName = SpaceBetween.displayName

export const StatContainer = ({ className, ...props }: CenterAlignedProps) => (
  <CenterAligned
    className={classNames("w-[90px] shrink-0 text-center", className)}
    {...props}
  />
)

export const StatText = ({
  size,
  weight,
  children,
  ...props
}: Omit<TextBodyProps, "asChild">) => (
  <Text
    asChild
    responsive
    size={size ?? "medium"}
    weight={weight ?? "semibold"}
    {...props}
  >
    <p>{children}</p>
  </Text>
)

export const StatHeader = ({
  color,
  size,
  weight,
  children,
  ...props
}: Omit<ComponentProps<typeof Text>, "asChild">) => (
  <Text
    {...props}
    asChild
    color={color ?? "secondary"}
    size={size ?? "small"}
    weight={weight ?? "regular"}
  >
    <p>{children}</p>
  </Text>
)
