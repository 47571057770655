import React, { useCallback, useMemo } from "react"
import { Media, useIsLessThanSm } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { useIsRarityEnabled } from "@/components/search/rarity"
import { IS_SERVER } from "@/constants/environment"
import { Button } from "@/design-system/Button"
import { DropdownProps } from "@/design-system/Dropdown"
import { Select, SelectOption } from "@/design-system/Select"
import { MobileDropdown } from "@/features/account/components/MobileDropdown"
import { useIsOpen } from "@/hooks/useIsOpen"
import { Search } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { SearchSortBy } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import { AssetSearchSortDropdown_collection$key } from "@/lib/graphql/__generated__/AssetSearchSortDropdown_collection.graphql"

export type Sort = {
  sortAscending: boolean
  sortBy: SearchSortBy
}

const DEFAULT_SORTS: Sort[] = [
  { sortAscending: false, sortBy: "ESTIMATED_VALUE" },
  { sortAscending: false, sortBy: "LISTING_DATE" },
  { sortAscending: false, sortBy: "CREATED_DATE" },
  { sortAscending: false, sortBy: "LAST_SALE_DATE" },
  { sortAscending: false, sortBy: "LAST_TRANSFER_DATE" },
  { sortAscending: true, sortBy: "EXPIRATION_DATE" },
  { sortAscending: true, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "LAST_SALE_PRICE" },
  { sortAscending: false, sortBy: "VIEWER_COUNT" },
  { sortAscending: false, sortBy: "FAVORITE_COUNT" },
  { sortAscending: true, sortBy: "CREATED_DATE" },
]

const COUNT_SORTS = ["VIEWER_COUNT", "FAVORITE_COUNT"]

type Props = {
  collection?: AssetSearchSortDropdown_collection$key | null
  hideCountSorts?: boolean
  setSort: (sort: Sort) => unknown
  searchState?: Pick<Search, "sortAscending" | "sortBy" | "collection">
  style?: React.CSSProperties
  className?: string
  sortOptions?: Sort[]
}

type SortBySelectOption = SelectOption<SearchSortBy> & {
  sortAscending?: boolean
}

const AssetSearchSortDropdown = ({
  collection: collectionDataKey,
  hideCountSorts,
  setSort,
  searchState,
  style,
  className,
  sortOptions = DEFAULT_SORTS,
}: Props) => {
  const t = useTranslate("components")
  const collection = useFragment(
    graphql`
      fragment AssetSearchSortDropdown_collection on CollectionType {
        ...useIsRarityEnabled_collection
      }
    `,
    collectionDataKey ?? null,
  )

  const isRarityDisplayed = useIsRarityEnabled(collection ?? null)

  const optionsSupported: SortBySelectOption[] = useMemo(() => {
    const sortLabels: Record<SearchSortBy, [string, string]> = {
      BIRTH_DATE: [
        t("assetSearchSortDropdown.birthDate.asc", "Oldest"),
        t("assetSearchSortDropdown.birthDate.desc", "Recently created"),
      ],
      CREATED_DATE: [
        t("assetSearchSortDropdown.createdDate.asc", "Oldest"),
        t("assetSearchSortDropdown.createdDate.desc", "Recently created"),
      ],
      EXPIRATION_DATE: [
        t("assetSearchSortDropdown.expirationDate.asc", "Ending soon"),
        t("assetSearchSortDropdown.expirationDate.desc", "Ending latest"),
      ],
      LISTING_DATE: [
        t("assetSearchSortDropdown.listingDate.asc", "Listed: oldest"),
        t("assetSearchSortDropdown.listingDate.desc", "Recently listed"),
      ],
      PRICE: [
        t("assetSearchSortDropdown.price.asc", "Price low to high"),
        t("assetSearchSortDropdown.price.desc", "Price high to low"),
      ],
      UNIT_PRICE: [
        t("assetSearchSortDropdown.unitPrice.asc", "Price low to high"),
        t("assetSearchSortDropdown.unitPrice.desc", "Price high to low"),
      ],
      LAST_SALE_DATE: [
        t("assetSearchSortDropdown.lastSaleDate.asc", "Sold longest ago"),
        t("assetSearchSortDropdown.lastSaleDate.desc", "Recently sold"),
      ],
      LAST_SALE_PRICE: [
        t("assetSearchSortDropdown.lastSalePrice.asc", "Lowest last sale"),
        t("assetSearchSortDropdown.lastSalePrice.desc", "Highest last sale"),
      ],
      LAST_TRANSFER_DATE: [
        t(
          "assetSearchSortDropdown.lastTransferDate.asc",
          "Least recently transferred",
        ),
        t("assetSearchSortDropdown.lastTransferDate.desc", "Recently received"),
      ],
      VIEWER_COUNT: [
        t("assetSearchSortDropdown.viewerCount.asc", "Fewest viewers"),
        t("assetSearchSortDropdown.viewerCount.desc", "Most viewed"),
      ],
      FAVORITE_COUNT: [
        t("assetSearchSortDropdown.favoriteCount.asc", "Least favorited"),
        t("assetSearchSortDropdown.favoriteCount.desc", "Most favorited"),
      ],
      RARITY_RANK: [
        t("assetSearchSortDropdown.rarityRank.asc", "Most rare"),
        t("assetSearchSortDropdown.rarityRank.desc", "Least rare"),
      ],
      STAFF_SORT_1: [
        t("assetSearchSortDropdown.staffSort1.asc", "Rev staff sort 1"),
        t("assetSearchSortDropdown.staffSort1.desc", "Staff sort 1"),
      ],
      STAFF_SORT_2: [
        t("assetSearchSortDropdown.staffSort2.asc", "Rev staff sort 2"),
        t("assetSearchSortDropdown.staffSort2.desc", "Staff sort 2"),
      ],
      STAFF_SORT_3: [
        t("assetSearchSortDropdown.staffSort3.asc", "Rev staff sort 3"),
        t("assetSearchSortDropdown.staffSort3.desc", "Staff sort 3"),
      ],
      ESTIMATED_VALUE: [
        t(
          "assetSearchSortDropdown.estimatedValue.asc",
          "Lowest estimated value",
        ),
        t("assetSearchSortDropdown.estimatedValue.desc", "Estimated value"),
      ],
      FLOOR_PRICE: [
        t("assetSearchSortDropdown.floorPrice.asc", "Lowest floor"),
        t("assetSearchSortDropdown.floorPrice.desc", "Highest floor"),
      ],
      BEST_BID: [
        t("assetSearchSortDropdown.bestBid.asc", "Lowest offer"),
        t("assetSearchSortDropdown.bestBid.desc", "Best offer"),
      ],
      "%future added value": ["", ""],
    }
    return Object.entries(sortLabels).flatMap(([sortBy, labels]) =>
      labels.map(
        (label, index): SortBySelectOption => ({
          label,
          key: label,
          value: sortBy as SearchSortBy,
          sortAscending: index === 0,
        }),
      ),
    )
  }, [t])

  const optionsShown: SortBySelectOption[] = useMemo(() => {
    const mapToSortOption = (sort: Sort): SortBySelectOption | undefined => {
      const { sortBy, sortAscending } = sort
      return optionsSupported.find(
        option =>
          option.value === sortBy && option.sortAscending === sortAscending,
      )
    }

    let resolvedSortOptions = sortOptions
    if (!isRarityDisplayed) {
      resolvedSortOptions = resolvedSortOptions.filter(
        option => option.sortBy !== "RARITY_RANK",
      )
    }
    if (hideCountSorts) {
      resolvedSortOptions = resolvedSortOptions.filter(
        option => !COUNT_SORTS.includes(option.sortBy),
      )
    }
    return resolvedSortOptions
      .map(mapToSortOption)
      .filter((option): option is SortBySelectOption => option !== undefined)
  }, [optionsSupported, sortOptions, isRarityDisplayed, hideCountSorts])

  const isMobile = useIsLessThanSm()

  const setSortWithOption = useCallback(
    (option: SortBySelectOption) => {
      setSort({
        sortBy: option.value,
        sortAscending: option.sortAscending ?? false,
      })
    },
    [setSort],
  )

  let appendTo: DropdownProps<SortBySelectOption>["appendTo"]

  if (!IS_SERVER) {
    appendTo = "parent"
  }

  const select = (
    <Select
      className={className}
      clearable={false}
      matcher={(option, value) =>
        option.value === value &&
        option.sortAscending === Boolean(searchState?.sortAscending)
      }
      maxHeight={isMobile ? "337px" : "initial"}
      options={optionsShown}
      optionsSupported={optionsSupported}
      overrides={{
        Dropdown: {
          props: {
            appendTo,
            popperOptions: {
              modifiers: [{ name: "flip", enabled: false }],
            },
          },
        },
      }}
      placeholder={t("assetSearchSortDropdown.sort", "Sort")}
      readOnly
      style={style}
      value={searchState?.sortBy}
      onSelect={option => {
        if (option) {
          setSortWithOption(option)
        }
      }}
    />
  )

  const { isOpen, open, close } = useIsOpen()
  return (
    <>
      <Media greaterThanOrEqual="lg">{select}</Media>
      <Media lessThan="lg">
        <Button
          aria-label={t("assetSearchSortDropdown.sort", "Sort")}
          className="h-full"
          data-testid="asset-search-sort-dropdown-trigger"
          icon="swap_vert"
          variant="secondary"
          onClick={open}
        />
        <MobileDropdown
          isOpen={isOpen}
          title={t("assetSearchSortDropdown.sort", "Sort")}
          onClose={close}
        >
          <MobileDropdown.Select
            isSelected={option =>
              searchState?.sortAscending === option.sortAscending &&
              searchState?.sortBy === option.value
            }
            keyName={option => `${option.value}-${option.sortAscending}`}
            options={optionsShown}
            onSelect={option => {
              setSortWithOption(option)
              close()
            }}
          />
        </MobileDropdown>
      </Media>
    </>
  )
}

export default AssetSearchSortDropdown
