import React, { CSSProperties } from "react"
import { range } from "lodash"
import { Block } from "@/design-system/Block"
import { Skeleton } from "@opensea/ui-kit"
import { NBSP } from "@/lib/helpers/stringUtils"
import { Table } from "."

type TableRowSkeletonProps = {
  height?: CSSProperties["height"]
}

export const TableRowSkeleton = ({ height }: TableRowSkeletonProps) => {
  return (
    <Table.Row>
      <Table.Cell height={height}>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell height={height}>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell height={height}>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell height={height}>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell height={height}>
        <Skeleton.Line />
      </Table.Cell>
    </Table.Row>
  )
}

export type TableSkeletonProps = {
  rowCount?: number
  columnCount?: number
  rowHeight?: CSSProperties["height"]
  maxHeight?: CSSProperties["maxHeight"]
  headers?: React.ReactNode[]
}

export const TableSkeleton = ({
  rowCount = 10,
  columnCount = 5,
  rowHeight = "55px",
  maxHeight,
  headers,
}: TableSkeletonProps) => {
  return (
    <Block maxHeight={maxHeight} overflow="scroll">
      <Table headers={headers ?? Array(columnCount).fill(NBSP)}>
        {range(rowCount).map((_, index) => (
          <TableRowSkeleton height={rowHeight} key={index} />
        ))}
      </Table>
    </Block>
  )
}
