import { ConnectionHandler } from "react-relay"
import {
  RecordProxy,
  RecordSourceProxy,
  RecordSourceSelectorProxy,
} from "relay-runtime"
import { ItemCard_data$data } from "@/lib/graphql/__generated__/ItemCard_data.graphql"
import { ItemCardFooter$data } from "@/lib/graphql/__generated__/ItemCardFooter.graphql"
import { useAssetPageListingEventSubscription$data } from "@/lib/graphql/__generated__/useAssetPageListingEventSubscription.graphql"
import { useAssetPageListingUpdatedEventSubscription$data } from "@/lib/graphql/__generated__/useAssetPageListingUpdatedEventSubscription.graphql"
import { useAssetPageOfferEventSubscription$data } from "@/lib/graphql/__generated__/useAssetPageOfferEventSubscription.graphql"
import { useAssetPageOfferUpdatedEventSubscription$data } from "@/lib/graphql/__generated__/useAssetPageOfferUpdatedEventSubscription.graphql"
import { useCollectionPageListingEventSubscription$data } from "@/lib/graphql/__generated__/useCollectionPageListingEventSubscription.graphql"
import { useCollectionPageListingUpdatedEventSubscription$data } from "@/lib/graphql/__generated__/useCollectionPageListingUpdatedEventSubscription.graphql"
import { bn } from "@/lib/helpers/numberUtils"

const ACCEPT_HIGHEST_OFFER = "tradeSummary(excludeAccountAsMaker:true)"
const TRADE_SUMMARY = "tradeSummary"
const TRADE_SUMMARY_TYPE = "TradeSummaryType"

const ASSET_OWNERSHIP_TYPE = "AssetOwnershipDataType"

const BEST_ASK = "bestAsk"
const BEST_BID = "bestBid"
const INSERTED_AT = "insertedAt"
const QUANTITY = "quantity"

const ITEM_TYPE_EDGE = "ItemTypeEdge"
const ORDER_V2_TYPE_EDGE = "OrderV2TypeEdge"

export const EDGES = "edges"
export const ROOT = "root"

export const COLLECTION_CONNECTION_KEY =
  "CollectionAssetSearchListPagination_collectionItems"
export const ORDERS_CONNECTION_KEY = "Orders_orders"
export const LISTING_EVENT = "ListingEvent"
const BEST_LISTINGS = "TradeStationBuyTab_bestListings"
const BEST_OFFERS = "TradeStationSellTab_bestOffers"

export const LIST_VIEW_ITEM_REMOVAL_DELAY = 600 // 0.6s
export const GRID_VIEW_ITEM_REMOVAL_DELAY = 1250 // 1.25s

const ownershipKey = (address?: string) =>
  `ownership(identity:{"address":"${address}"})`

const sortAssetSearchEdges = (edges: RecordProxy[]) => {
  const sortByPrice = (
    a: (typeof edges)[number],
    b: (typeof edges)[number],
  ) => {
    const aPrice = getAssetPrice(a)
    const bPrice = getAssetPrice(b)
    if (aPrice.isEqualTo(bPrice)) {
      const aTokenId = getTokenId(a)
      const bTokenId = getTokenId(b)
      return aTokenId.isGreaterThan(bTokenId) ? 1 : -1
    }
    return aPrice.isGreaterThan(bPrice) ? 1 : -1
  }

  const getAssetPrice = (edge: (typeof edges)[number]) => {
    return bn(
      Number(
        edge
          .getLinkedRecord("node")
          ?.getLinkedRecord("orderData")
          ?.getLinkedRecord("bestAskV2")
          ?.getLinkedRecord("perUnitPriceType")
          ?.getValue("usd") ?? 0,
      ),
    )
  }

  const getTokenId = (edge: (typeof edges)[number]) => {
    if (!isSolanaTokenId(edge)) {
      return bn(Number(edge.getLinkedRecord("node")?.getValue("tokenId") ?? 0))
    }
    return bn(0)
  }

  const isSolanaTokenId = (edge: (typeof edges)[number]): boolean => {
    const chain = String(
      edge
        .getLinkedRecord("node")
        ?.getLinkedRecord("chain")
        ?.getValue("identifier") ?? "",
    )
    return chain === "SOLANA" || chain === "SOLDEV"
  }

  edges.sort((a, b) => sortByPrice(a, b))
}

const sortOrdersEdges = (edges: RecordProxy[], sortAscending: boolean) => {
  const sortByPrice = (
    a: (typeof edges)[number],
    b: (typeof edges)[number],
  ) => {
    const aPrice = getOrderPrice(a)
    const bPrice = getOrderPrice(b)
    if (sortAscending) {
      return aPrice.isGreaterThan(bPrice)
        ? 1
        : aPrice.isLessThan(bPrice)
        ? -1
        : 0
    }
    return aPrice.isGreaterThan(bPrice) ? -1 : aPrice.isLessThan(bPrice) ? 1 : 0
  }

  const getOrderPrice = (edge: (typeof edges)[number]) => {
    return bn(
      Number(
        edge
          .getLinkedRecord("node")
          ?.getLinkedRecord("perUnitPriceType")
          ?.getValue("eth") ?? 0,
      ),
    )
  }

  edges.sort((a, b) => sortByPrice(a, b))
}

export const getEdgeRelayId = (edge: RecordProxy): string => {
  return String(edge.getLinkedRecord("node")?.getValue("relayId"))
}

const shouldRemoveOrder = (order: RecordProxy) => {
  const remainingQuantity = Number(
    order.getValue("remainingQuantityType") ?? "-1",
  )
  return remainingQuantity < 1
}

export const getAssetSearchConnectionFilters = (
  slug?: string,
  isBuyNowToggled?: boolean,
) => {
  const filters = {
    collections: [slug],
    prioritizeBuyNow: true,
    resultType: "ASSETS",
    sortAscending: true,
    sortBy: "UNIT_PRICE",
  }
  return isBuyNowToggled ? { ...filters, toggles: ["BUY_NOW"] } : filters
}

export const getListingOrdersConnectionFilters = (
  assetContractAddress: string,
  chain: string,
  tokenId: string,
) => {
  return {
    filterByOrderRules: false,
    includeCriteriaOrders: false,
    isExpired: false,
    isValid: true,
    makerArchetype: {
      assetContractAddress,
      chain,
      tokenId,
    },
    sortAscending: true,
    sortBy: "PRICE",
    takerAssetIsPayment: true,
  }
}

export const getOfferOrdersConnectionFilters = (
  assetContractAddress: string,
  chain: string,
  tokenId: string,
) => {
  return {
    filterByOrderRules: true,
    includeCriteriaOrders: true,
    isExpired: false,
    isValid: true,
    makerAssetIsPayment: true,
    sortBy: "PRICE",
    takerArchetype: {
      assetContractAddress,
      chain,
      tokenId,
    },
  }
}

const getTradeStationConnectionFilters = (
  assetContractAddress: string,
  chain: string,
  tokenId: string,
) => {
  return {
    asset: {
      assetContractAddress,
      chain,
      tokenId,
    },
    forTaker: {},
  }
}

export const removeItemFromStore = (
  item: ItemCardFooter$data | ItemCard_data$data,
  store: RecordSourceProxy,
) => {
  removeItemFromConnection({ item, store, isBuyNowToggled: true })
  removeItemFromConnection({ item, store, isBuyNowToggled: false })
  cleanClientSideItem(store, item.relayId)
}

const removeItemFromConnection = ({
  item,
  store,
  isBuyNowToggled,
}: {
  item: ItemCardFooter$data | ItemCard_data$data
  store: RecordSourceProxy
  isBuyNowToggled: boolean
}) => {
  const connectionID = ConnectionHandler.getConnectionID(
    ROOT,
    COLLECTION_CONNECTION_KEY,
    getAssetSearchConnectionFilters(
      item.collection?.slug ?? item.bundleCollection?.slug,
      isBuyNowToggled,
    ),
  )

  // Remove asset from connection
  const assetSearchConnection = store.get(connectionID)
  if (assetSearchConnection) {
    ConnectionHandler.deleteNode(assetSearchConnection, item.relayId)
  }
}

const cleanClientSideItem = (store: RecordSourceProxy, relayId: string) => {
  const asset = store.get(relayId)
  asset?.setValue(false, "saleOrListingCancellationOccurred")
  const pendingTxn = asset?.getOrCreateLinkedRecord(
    "pendingTxn",
    "PendingTxnType",
  )
  pendingTxn?.setValue(null, "priorityFeeGwei")
  pendingTxn?.setValue(null, "maxFeeGwei")
  pendingTxn?.setValue(null, "seenAt")
  pendingTxn?.setValue(null, "status")
  pendingTxn?.setValue(null, "transactionStatus")
  pendingTxn?.setValue(null, "blockExplorerLink")
}

export const decrementOwnedQuantity = (
  store: RecordSourceProxy,
  relayId: string,
  address?: string,
) => {
  const asset = store.get(relayId)
  const ownership = asset?.getOrCreateLinkedRecord(
    ownershipKey(address),
    ASSET_OWNERSHIP_TYPE,
  )
  if (ownership) {
    const ownedQuantity = Number(ownership.getValue(QUANTITY) ?? 0)
    ownership.setValue(ownedQuantity - 1, QUANTITY)
  }
}

export const insertItem = (
  store:
    | RecordSourceSelectorProxy<useCollectionPageListingEventSubscription$data>
    | RecordSourceSelectorProxy<useCollectionPageListingUpdatedEventSubscription$data>,
  asset: RecordProxy,
  assetSearchConnection: RecordProxy,
  relayId: string,
  orderRelayId: string,
) => {
  const newListing = ConnectionHandler.createEdge(
    store,
    assetSearchConnection,
    asset,
    ITEM_TYPE_EDGE,
  )
  const previousAssets = assetSearchConnection.getLinkedRecords(EDGES)
  if (!previousAssets) {
    return
  }
  const newAssets = [
    ...previousAssets.filter(edge => getEdgeRelayId(edge) !== relayId),
    newListing,
  ]
  sortAssetSearchEdges(newAssets)
  // Do not insert a new listing if it would be shown last,
  // as the price is too high for the current pagination
  const numAssets = newAssets.length

  if (numAssets > 1 && getEdgeRelayId(newAssets[numAssets - 1]) !== relayId) {
    const order = store.get(orderRelayId)
    order?.setValue(new Date().toISOString(), INSERTED_AT)
    assetSearchConnection.setLinkedRecords(newAssets, EDGES)
  }
}

export const insertOrder = (
  store: RecordSourceSelectorProxy<
    | useAssetPageListingEventSubscription$data
    | useAssetPageOfferEventSubscription$data
  >,
  order: RecordProxy,
  connection: RecordProxy,
  sortAscending = true,
) => {
  const newOrder = ConnectionHandler.createEdge(
    store,
    connection,
    order,
    ORDER_V2_TYPE_EDGE,
  )
  const previousOrders = connection.getLinkedRecords(EDGES)
  if (previousOrders) {
    const newOrders = [
      ...previousOrders.filter(
        edge => getEdgeRelayId(edge) !== getEdgeRelayId(newOrder),
      ),
      newOrder,
    ]
    sortOrdersEdges(newOrders, sortAscending)
    connection.setLinkedRecords(newOrders, EDGES)
  }
}

export const removeOrder = (orderRelayId: string, connection: RecordProxy) => {
  const orders = connection.getLinkedRecords(EDGES)
  if (orders) {
    const filteredOrders = [
      ...orders.filter(edge => getEdgeRelayId(edge) !== orderRelayId),
    ]
    connection.setLinkedRecords(filteredOrders, EDGES)
  }
}

export const updateTradeSummaryBestAsk = (
  store: RecordSourceSelectorProxy<
    | useAssetPageListingUpdatedEventSubscription$data
    | useAssetPageListingEventSubscription$data
  >,
  asset: RecordProxy,
  order: RecordProxy | null,
  ordersConnection: RecordProxy,
) => {
  const tradeSummary = asset.getOrCreateLinkedRecord(
    TRADE_SUMMARY,
    TRADE_SUMMARY_TYPE,
  )
  if (order) {
    tradeSummary.setLinkedRecord(order, BEST_ASK)
  } else {
    const orders = ordersConnection.getLinkedRecords(EDGES)
    if (orders && orders.length > 0) {
      const derivedBestOrderRelayId = getEdgeRelayId(orders[0])
      const derivedBestOrder = store.get(derivedBestOrderRelayId)
      if (derivedBestOrder) {
        tradeSummary.setLinkedRecord(derivedBestOrder, BEST_ASK)
      } else {
        tradeSummary.setValue(null, BEST_ASK)
      }
    } else {
      tradeSummary.setValue(null, BEST_ASK)
    }
  }
}

export const updateTradeSummaryBestBid = (
  store: RecordSourceSelectorProxy<
    | useAssetPageOfferEventSubscription$data
    | useAssetPageOfferUpdatedEventSubscription$data
  >,
  asset: RecordProxy,
  order: RecordProxy | null,
  ordersConnection: RecordProxy,
  updateAcceptHighestOffer = false,
) => {
  const tradeSummary = asset.getOrCreateLinkedRecord(
    updateAcceptHighestOffer ? ACCEPT_HIGHEST_OFFER : TRADE_SUMMARY,
    TRADE_SUMMARY_TYPE,
  )
  if (order) {
    tradeSummary.setLinkedRecord(order, BEST_BID)
  } else {
    const orders = ordersConnection.getLinkedRecords(EDGES)
    if (orders && orders.length > 0) {
      // TODO: Need to filter out users own orders for updating accept highest offer
      const derivedBestOrderRelayId = getEdgeRelayId(orders[0])
      const derivedBestOrder = store.get(derivedBestOrderRelayId)
      if (derivedBestOrder) {
        tradeSummary.setLinkedRecord(derivedBestOrder, BEST_BID)
      } else {
        tradeSummary.setValue(null, BEST_BID)
      }
    } else {
      tradeSummary.setValue(null, BEST_BID)
    }
  }
}

export const updateCollectionStoreWithListingUpdatedEventData = (
  store: RecordSourceSelectorProxy<useCollectionPageListingUpdatedEventSubscription$data>,
  data: useCollectionPageListingUpdatedEventSubscription$data,
  slug: string,
) => {
  const relayId = data.listingUpdatedEvent.itemRelayId
  if (!relayId) {
    return
  }
  const item = store.get(relayId)
  if (!item) {
    return
  }

  const newBestOrderRelayId = data.listingUpdatedEvent.newBestOrderRelayId

  if (!newBestOrderRelayId) {
    item.setValue(true, "saleOrListingCancellationOccurred")
    return
  }

  const newBestOrder = store.get(newBestOrderRelayId)
  if (!newBestOrder) {
    return
  }

  cleanClientSideItem(store, relayId)
  item.getLinkedRecord("orderData")?.setLinkedRecord(newBestOrder, "bestAskV2")

  updateStoreWithListingEventDataForBuyNow({
    store,
    relayId,
    orderRelayId: newBestOrderRelayId,
    slug,
    isBuyNowToggled: true,
  })

  updateStoreWithListingEventDataForBuyNow({
    store,
    relayId,
    orderRelayId: newBestOrderRelayId,
    slug,
    isBuyNowToggled: false,
  })
}

export const updateCollectionStoreWithListingEventData = (
  store: RecordSourceSelectorProxy<useCollectionPageListingEventSubscription$data>,
  data: useCollectionPageListingEventSubscription$data,
  slug: string,
) => {
  if (data.listingEvent.__typename !== LISTING_EVENT) {
    return
  }
  const orderRelayId = data.listingEvent.sellOrderRelayId
  const paymentAssetRelayId = data.listingEvent.paymentAssetRelayId
  const relayId = data.listingEvent.relayId
  if (!relayId || !orderRelayId || !paymentAssetRelayId) {
    return
  }

  cleanClientSideItem(store, relayId)

  updateStoreWithListingEventDataForBuyNow({
    store,
    relayId,
    orderRelayId,
    slug,
    isBuyNowToggled: true,
  })
  updateStoreWithListingEventDataForBuyNow({
    store,
    relayId,
    orderRelayId,
    slug,
    isBuyNowToggled: false,
  })
}

const updateStoreWithListingEventDataForBuyNow = ({
  store,
  relayId,
  orderRelayId,
  slug,
  isBuyNowToggled,
}: {
  store:
    | RecordSourceSelectorProxy<useCollectionPageListingEventSubscription$data>
    | RecordSourceSelectorProxy<useCollectionPageListingUpdatedEventSubscription$data>
  relayId: string
  orderRelayId: string
  slug: string
  isBuyNowToggled: boolean
}) => {
  const connectionID = ConnectionHandler.getConnectionID(
    ROOT,
    COLLECTION_CONNECTION_KEY,
    getAssetSearchConnectionFilters(slug, isBuyNowToggled),
  )
  const asset = store.get(relayId)
  const assetSearchConnection = store.get(connectionID)
  if (asset && assetSearchConnection) {
    insertItem(store, asset, assetSearchConnection, relayId, orderRelayId)
  }
}

export const updateAssetStoreWithListingEventData = (
  store: RecordSourceSelectorProxy<useAssetPageListingEventSubscription$data>,
  data: useAssetPageListingEventSubscription$data,
  contractAddress: string,
  chain: string,
  tokenId: string,
) => {
  const listingEvent = data.listingEvent
  if (
    listingEvent.__typename !== LISTING_EVENT ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !listingEvent.item?.orderData?.bestAskV2
  ) {
    return
  }
  const asset = store.get(listingEvent.relayId)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const order = store.get(listingEvent.item.orderData?.bestAskV2.relayId)

  const ordersConnectionID = ConnectionHandler.getConnectionID(
    ROOT,
    ORDERS_CONNECTION_KEY,
    getListingOrdersConnectionFilters(contractAddress, chain, tokenId),
  )
  const ordersConnection = store.get(ordersConnectionID)

  const bestListingsConnectionID = ConnectionHandler.getConnectionID(
    ROOT,
    BEST_LISTINGS,
    getTradeStationConnectionFilters(contractAddress, chain, tokenId),
  )
  const bestListingsConnection = store.get(bestListingsConnectionID)

  if (asset && order && ordersConnection) {
    insertOrder(store, order, ordersConnection)
    if (bestListingsConnection) {
      insertOrder(store, order, bestListingsConnection)
    }
    updateTradeSummaryBestAsk(store, asset, null, ordersConnection)
  }
}

export const updateAssetStoreWithListingUpdatedEventData = (
  store: RecordSourceSelectorProxy<useAssetPageListingUpdatedEventSubscription$data>,
  data: useAssetPageListingUpdatedEventSubscription$data,
  contractAddress: string,
  chain: string,
  tokenId: string,
) => {
  const listingUpdatedEvent = data.listingUpdatedEvent
  const orderRelayId = listingUpdatedEvent.orderRelayId

  const asset = store.get(listingUpdatedEvent.itemRelayId)
  const connectionID = ConnectionHandler.getConnectionID(
    ROOT,
    ORDERS_CONNECTION_KEY,
    getListingOrdersConnectionFilters(contractAddress, chain, tokenId),
  )
  const ordersConnection = store.get(connectionID)

  const bestListingsConnectionID = ConnectionHandler.getConnectionID(
    ROOT,
    BEST_LISTINGS,
    getTradeStationConnectionFilters(contractAddress, chain, tokenId),
  )
  const bestListingsConnection = store.get(bestListingsConnectionID)

  if (asset && ordersConnection && orderRelayId) {
    const order = store.get(orderRelayId)
    if (!listingUpdatedEvent.order || (order && shouldRemoveOrder(order))) {
      removeOrder(orderRelayId, ordersConnection)
      if (bestListingsConnection) {
        removeOrder(orderRelayId, bestListingsConnection)
      }
    }
    const newBestOrder = listingUpdatedEvent.newBestOrderRelayId
      ? store.get(listingUpdatedEvent.newBestOrderRelayId)
      : null
    updateTradeSummaryBestAsk(
      store,
      asset,
      newBestOrder ?? null,
      ordersConnection,
    )
  }
}

export const updateAssetStoreWithOfferEventData = (
  store: RecordSourceSelectorProxy<useAssetPageOfferEventSubscription$data>,
  data: useAssetPageOfferEventSubscription$data,
  contractAddress: string,
  chain: string,
  tokenId: string,
  isMaker: boolean,
) => {
  const offerEvent = data.offerEvent
  if (!offerEvent.order) {
    return
  }
  const asset = store.get(offerEvent.itemRelayId)
  const order = store.get(offerEvent.orderRelayId)

  const ordersConnectionID = ConnectionHandler.getConnectionID(
    ROOT,
    ORDERS_CONNECTION_KEY,
    getOfferOrdersConnectionFilters(contractAddress, chain, tokenId),
  )
  const ordersConnection = store.get(ordersConnectionID)

  const bestOffersConnectionID = ConnectionHandler.getConnectionID(
    ROOT,
    BEST_OFFERS,
    getTradeStationConnectionFilters(contractAddress, chain, tokenId),
  )
  const bestOffersConnection = store.get(bestOffersConnectionID)

  if (asset && order && ordersConnection) {
    // Insert offer into list of offers below listings
    insertOrder(store, order, ordersConnection, false)
    // Update the trade summary
    updateTradeSummaryBestBid(store, asset, null, ordersConnection)
    if (!isMaker) {
      if (bestOffersConnection) {
        // Add offer to list of purchasable offers in 1155 trade station
        insertOrder(store, order, bestOffersConnection, false)
      }
      // Update the accept highest offer button
      updateTradeSummaryBestBid(store, asset, null, ordersConnection, true)
    }
  }
}

export const updateAssetStoreWithOfferUpdatedEventData = (
  store: RecordSourceSelectorProxy<useAssetPageOfferUpdatedEventSubscription$data>,
  data: useAssetPageOfferUpdatedEventSubscription$data,
  contractAddress: string,
  chain: string,
  tokenId: string,
) => {
  const offerUpdatedEvent = data.offerUpdatedEvent
  const orderRelayId = offerUpdatedEvent.orderRelayId

  const asset = store.get(offerUpdatedEvent.itemRelayId)
  const ordersConnectionID = ConnectionHandler.getConnectionID(
    ROOT,
    ORDERS_CONNECTION_KEY,
    getOfferOrdersConnectionFilters(contractAddress, chain, tokenId),
  )
  const ordersConnection = store.get(ordersConnectionID)

  const bestOffersConnectionID = ConnectionHandler.getConnectionID(
    ROOT,
    BEST_OFFERS,
    getTradeStationConnectionFilters(contractAddress, chain, tokenId),
  )
  const bestOffersConnection = store.get(bestOffersConnectionID)

  if (asset && ordersConnection && orderRelayId) {
    const order = store.get(orderRelayId)
    if (!offerUpdatedEvent.order || (order && shouldRemoveOrder(order))) {
      removeOrder(orderRelayId, ordersConnection)
      if (bestOffersConnection) {
        removeOrder(orderRelayId, bestOffersConnection)
      }
    }
    const newBestOrder = offerUpdatedEvent.newBestOrderRelayId
      ? store.get(offerUpdatedEvent.newBestOrderRelayId)
      : null
    updateTradeSummaryBestBid(
      store,
      asset,
      newBestOrder ?? null,
      ordersConnection,
    )
    updateTradeSummaryBestBid(
      store,
      asset,
      newBestOrder ?? null,
      ordersConnection,
      true,
    )
  }
}
