import React, { useEffect, useState } from "react"
import { CenterAligned, Spinner } from "@opensea/ui-kit"
import { useInView, IntersectionOptions } from "react-intersection-observer"
import { LoadMoreFn } from "react-relay"
import { OperationType } from "relay-runtime"
import { PageProps } from "@/lib/graphql/graphql"
import { IS_SERVER } from "../../constants/environment"
import { Block } from "../Block"

export type ScrollingPaginatorProps = {
  intersectionOptions?: IntersectionOptions
  isFirstPageLoading?: boolean
  disableLoader?: boolean
  page: PageProps
  size: number
  children?: React.ReactNode
  onLoad?: () => unknown
  onLoadStart?: () => unknown
}

// TODO: Temporary until we change ScrollingPaginator page into usePaginationFragment page props
export const loadNextToLoadMore = <TQuery extends OperationType>({
  loadNext,
  count,
}: {
  loadNext: LoadMoreFn<TQuery>
  count: number
}) =>
  new Promise<void>((resolve, reject) => {
    loadNext(count, {
      onComplete: err => {
        !err ? resolve() : reject(err)
      },
    })
  })

async function loadPolyfills() {
  if (typeof window.IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

if (!IS_SERVER) {
  loadPolyfills()
}

export const ScrollingPaginator = ({
  disableLoader,
  isFirstPageLoading,
  intersectionOptions,
  page: { hasMore, loadMore, isLoading: getIsPageLoading },
  children,
  onLoad,
  onLoadStart,
  size,
}: ScrollingPaginatorProps) => {
  const { ref, inView } = useInView(intersectionOptions)
  const [isLoading, setIsLoading] = useState(false)

  const isPageLoading = getIsPageLoading()
  const pageHasMore = hasMore()

  const paginate = async () => {
    if (onLoadStart) {
      onLoadStart()
    }
    setIsLoading(true)
    await loadMore(size)
    // avoid loading twice by setting after re-render when paginator might be out of view
    setTimeout(() => setIsLoading(false), 0)
    if (onLoad) {
      onLoad()
    }
  }

  useEffect(() => {
    if (
      inView &&
      pageHasMore &&
      !isLoading &&
      !isPageLoading &&
      !disableLoader
    ) {
      paginate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, pageHasMore, isLoading, isPageLoading, disableLoader])

  const renderContent = () => {
    if ((isLoading || isFirstPageLoading) && !disableLoader) {
      return (
        <Block margin="8px">
          <Spinner />
        </Block>
      )
    }

    if (!pageHasMore) {
      return null
    }

    if (!isLoading && !isFirstPageLoading) {
      // Being at least a pixel ensures that the intersection observer registers that it's in view
      return <Block height="1px" width="1px" />
    }

    if (children) {
      return children
    }

    return null
  }

  return <CenterAligned ref={ref}>{renderContent()}</CenterAligned>
}
