import React from "react"
import { Flex, Media, classNames } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"

type Props = {
  header: React.ReactNode
  banner?: React.ReactNode
  filter: React.ReactNode
  pills: React.ReactNode
  assets: React.ReactNode
  resultsHeader?: React.ReactNode
  counts?: React.ReactNode
  footer?: React.ReactNode
  "data-testid": string
}

export const AssetViewLayout = React.forwardRef<HTMLDivElement, Props>(
  function AssetViewLayout(
    {
      header,
      filter,
      pills,
      banner,
      assets,
      resultsHeader,
      counts,
      footer,
      ...rest
    },
    ref,
  ) {
    return (
      <>
        {header}
        <Flex className="w-full pt-0 lg:pt-6" ref={ref} {...rest}>
          {filter}
          <Block className="w-full" minWidth={0} paddingBottom="80px">
            {resultsHeader && (
              <Block
                className="w-full"
                marginBottom={{ _: "16px", lg: "10px" }}
                marginTop={{ _: "8px", lg: "0px" }}
              >
                {resultsHeader}
              </Block>
            )}

            {counts && (
              <Media greaterThanOrEqual="md">
                {(mediaClassNames: string | undefined) => (
                  <Flex
                    className={classNames(
                      "my-2 w-full items-center",
                      mediaClassNames,
                    )}
                  >
                    {counts}
                  </Flex>
                )}
              </Media>
            )}

            {pills}

            {banner}

            {assets}
          </Block>
        </Flex>
        {footer}
      </>
    )
  },
)
