import { usePrevious } from "react-use"
import { v4 as uuidv4 } from "uuid"

export const useMasonicRerenderKey = <T>(items: T[]) => {
  const previousItemsLength = usePrevious(items.length) ?? -1

  // We only want to re-render when the current items length is lower than the previous items length
  // due to this issue with Masonic when removing items: https://github.com/jaredLunde/masonic/issues/12
  const key = items.length < previousItemsLength ? uuidv4() : undefined
  const previousKey = usePrevious(key)
  return key ?? previousKey
}
