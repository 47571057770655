import { useCallback } from "react"
import { useTranslate } from "@/hooks/useTranslate"
import {
  OfferTypeToggle,
  OrderStatusToggle,
} from "@/lib/graphql/__generated__/AccountOffersOrderSearchListPaginationQuery.graphql"
import { snakeCaseToSentenceCase } from "@/lib/helpers/stringUtils"
import { Item } from "../featured-filter"

export const useOrderStatusItems = (): Item<OrderStatusToggle>[] => {
  const t = useTranslate("components")
  return [
    {
      filter: "ACTIVE",
      label: t("orderSearchFilter.status.active", "Active"),
    },
    {
      filter: "EXPIRING",
      label: t("orderSearchFilter.status.expiring", "Expiring"),
    },
    {
      filter: "INACTIVE",
      label: t("orderSearchFilter.status.inactive", "Inactive"),
    },
  ]
}

export const useOfferTypeItems = (): Item<OfferTypeToggle>[] => {
  const t = useTranslate("components")
  return [
    {
      filter: "SINGLE",
      label: t("orderSearchFilter.offerType.singleOffers", "Single offers"),
    },
    {
      filter: "COLLECTION",
      label: t(
        "orderSearchFilter.offerType.collectionOffers",
        "Collection offers",
      ),
    },
    {
      filter: "TRAIT",
      label: t(
        "orderSearchFilter.offerType.traitCollectionOffers",
        "Trait collection offers",
      ),
    },
  ]
}

export const useGetOrderStatusItemLabel = (): ((
  status: OrderStatusToggle,
) => string) => {
  const orderStatusItems = useOrderStatusItems()
  return useCallback(
    (status: OrderStatusToggle) => {
      const statusItem = orderStatusItems.find(item => item.filter === status)
      return statusItem?.label ?? snakeCaseToSentenceCase(status)
    },
    [orderStatusItems],
  )
}

export const useGetOfferTypeItemLabel = (): ((
  type: OfferTypeToggle,
) => string) => {
  const offerTypeItems = useOfferTypeItems()
  return useCallback(
    (type: OfferTypeToggle) => {
      const typeItem = offerTypeItems.find(item => item.filter === type)
      return typeItem?.label ?? snakeCaseToSentenceCase(type)
    },
    [offerTypeItems],
  )
}
