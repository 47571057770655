import React, { useCallback } from "react"
import { useIsHydrated, useIsLessThanBreakpoint } from "@opensea/ui-kit"
import {
  List,
  ListProps,
  RenderComponentProps,
  useInfiniteLoader,
} from "masonic"
import styled from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { ScrollingPaginatorProps } from "@/design-system/ScrollingPaginator"
import { useLoadMoreFromPagination } from "@/design-system/utils"
import { useMasonicRerenderKey } from "@/hooks/useMasonicRerenderKey"
import {
  FeatureTableRow,
  FeatureTableRowProps,
} from "./components/FeatureTableRow.react"
import {
  HeaderContainer,
  FullRowContainer,
  StatContainer,
  StatHeader,
  StatText,
} from "./styles"

const FeatureTableCell = styled(Block).attrs<BlockProps>(props => ({
  ...props,
  flexBasis: props.width,
}))``

FeatureTableCell.defaultProps = {
  display: "flex",
  flexBasis: "125px",
  width: "125px",
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: "center",
  alignItems: "center",
}

export type FeatureTableProps<T> = Pick<
  ListProps<T>,
  "items" | "itemHeightEstimate" | "overscanBy" | "itemKey"
> &
  Pick<
    FeatureTableRowProps<T>,
    | "renderPrimary"
    | "renderMore"
    | "renderFullRow"
    | "role"
    | "fullRowMinBreakpoint"
    | "getHref"
    | "onClick"
    | "showBorder"
    | "showInteractiveStyles"
  > & {
    header?: React.ReactNode
    pagination?: Omit<ScrollingPaginatorProps, "intersectionOptions">
    isItemLoaded?: (index: number, items: T[]) => boolean
    threshold?: number
  } & BlockProps

export const FeatureTableBase = <T,>({
  items,
  itemHeightEstimate,
  itemKey,
  header,
  fullRowMinBreakpoint = "xl",
  overscanBy,
  pagination,
  isItemLoaded,
  onClick,
  showBorder = true,
  showInteractiveStyles = false,
  renderPrimary,
  renderMore,
  renderFullRow,
  role = "list",
  getHref,
  // As a default, load when we're one row before the last element
  threshold = 1,
  ...rest
}: FeatureTableProps<T>) => {
  const isHydrated = useIsHydrated()
  const loadMore = useLoadMoreFromPagination(pagination)

  const maybeLoadMore = useInfiniteLoader(loadMore, {
    isItemLoaded,
    minimumBatchSize: pagination?.size ?? 16,
    threshold,
  })

  const shouldUseFullRow = !useIsLessThanBreakpoint(fullRowMinBreakpoint)

  const renderRow = useCallback(
    (props: RenderComponentProps<T>) => (
      <FeatureTableRow
        fullRowMinBreakpoint={fullRowMinBreakpoint}
        shouldUseFullRow={isHydrated ? shouldUseFullRow : undefined}
        {...props}
        getHref={getHref}
        renderFullRow={renderFullRow}
        renderMore={renderMore}
        renderPrimary={renderPrimary}
        showBorder={showBorder}
        showInteractiveStyles={showInteractiveStyles}
        onClick={onClick}
      />
    ),
    [
      fullRowMinBreakpoint,
      getHref,
      onClick,
      renderFullRow,
      renderMore,
      renderPrimary,
      shouldUseFullRow,
      isHydrated,
      showBorder,
      showInteractiveStyles,
    ],
  )

  const rerenderKey = useMasonicRerenderKey(items)

  return (
    <Block {...rest}>
      {header}
      <List
        itemHeightEstimate={itemHeightEstimate ?? 80}
        itemKey={itemKey}
        items={items}
        key={rerenderKey}
        overscanBy={overscanBy}
        render={renderRow}
        // @ts-expect-error masonic list only allows for grid or list but prop works fine
        role={role}
        onRender={maybeLoadMore}
      />
    </Block>
  )
}

export const FeatureTable = Object.assign(FeatureTableBase, {
  Row: FeatureTableRow,
  Cell: FeatureTableCell,
  HeaderContainer,
  FullRowContainer,
  StatContainer,
  StatHeader,
  StatText,
})
