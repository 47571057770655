import React from "react"
import MuxPlayer, { MuxPlayerProps } from "@mux/mux-player-react"
import { classNames } from "@opensea/ui-kit"

type MuxVideoProps = {
  containerStyle?: React.CSSProperties
  className?: string
  muxPlayerClassName?: string
} & MuxPlayerProps

export const MuxVideo = ({
  className,
  containerStyle,
  muxPlayerClassName,
  ...rest
}: MuxVideoProps) => {
  return (
    <div
      className={classNames(
        "w-full bg-black [--center-controls:none]",
        className,
      )}
      style={containerStyle}
    >
      <MuxPlayer
        className={muxPlayerClassName}
        streamType="on-demand"
        {...rest}
      />
    </div>
  )
}
