import React from "react"
import { Text, UnstyledButton } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"

type ShowMoreButtonProps = {
  showMore: boolean
  toggleShowMore: () => void
}

export const ShowMoreButton = ({
  showMore,
  toggleShowMore,
}: ShowMoreButtonProps) => {
  const t = useTranslate("designSystem")

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toggleShowMore()
  }

  return (
    <Block
      as={UnstyledButton}
      className="relative w-full min-w-[64px]"
      onClick={onClick}
    >
      <Text
        asChild
        className="mt-1"
        color="secondary"
        size="tiny"
        weight="semibold"
      >
        <p>
          {showMore
            ? t("showMore.less.label", "- Less")
            : t("showMore.more.label", "+ More")}
        </p>
      </Text>
    </Block>
  )
}
