import React from "react"
import { SearchContextProvider } from "@/components/search/utils/SearchContextProvider.react"
import { AssetViewLayout } from "../AssetViewLayout"

type Props = {
  header: React.ReactNode
  banner?: React.ReactNode
  filter: React.ReactNode
  pills: React.ReactNode
  assets: React.ReactNode
  resultsHeader?: React.ReactNode
  counts?: React.ReactNode
  footer?: React.ReactNode
}

export const AssetSearchLayout = React.forwardRef<HTMLDivElement, Props>(
  function AssetSearchLayout(props, ref) {
    return (
      <SearchContextProvider>
        <AssetViewLayout
          data-testid="AssetSearchLayout--results"
          ref={ref}
          {...props}
        />
      </SearchContextProvider>
    )
  },
)
