import React, { ComponentType, MouseEventHandler } from "react"
import { classNames } from "@opensea/ui-kit"
import { useToggle } from "react-use"
import styled from "styled-components"
import { Flex } from "../../Flex"
import { Item } from "../../Item"
import { pressedStyles, hoverStyles } from "../styles"
import {
  FeatureTableRenderProps,
  FeatureTableRowProps,
} from "./FeatureTableRow.react"
import { ShowMoreButton } from "./ShowMoreButton.react"

type CollapsedRowProps<T> = FeatureTableRenderProps<T> &
  Pick<FeatureTableRowProps<T>, "renderPrimary" | "renderMore"> & {
    href?: string
    onClick?: MouseEventHandler<HTMLElement>
    as?: keyof JSX.IntrinsicElements | ComponentType
  }

export const CollapsedRow = <T,>({
  className,
  href,
  renderPrimary,
  renderMore,
  onClick,
  ...renderProps
}: CollapsedRowProps<T>) => {
  const Primary = renderPrimary
  const More = renderMore
  const [isCollapsed, toggleCollapsed] = useToggle(true)

  return (
    <StatsItem
      className={classNames(
        "flex-col overflow-hidden border-0 border-b border-level-2",
        "py-3 pl-4 pr-0 xl:p-4",
        className,
      )}
      href={href || ""}
      role="row"
      onClick={onClick}
    >
      <Flex className="w-full">
        <Primary
          {...renderProps}
          ShowMoreButton={() => (
            <ShowMoreButton
              showMore={!isCollapsed}
              toggleShowMore={toggleCollapsed}
            />
          )}
          isCollapsed={isCollapsed}
          toggleCollapsed={toggleCollapsed}
        />
      </Flex>
      {!isCollapsed && (
        <Item.Footer className="z-[1] overflow-auto">
          <More {...renderProps} />
        </Item.Footer>
      )}
    </StatsItem>
  )
}

export const StatsItem = styled(Item)`
  && {
    border: none;
    border-radius: ${props => props.theme.borderRadius.medium};
    cursor: pointer;
    ${hoverStyles};
    ${pressedStyles};
  }
`
