import React from "react"
import { classNames } from "@opensea/ui-kit"
import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { Block, BlockProps } from "@/design-system/Block"
import { TableSkeleton, TableRowSkeleton } from "./TableSkeleton.react"
import { getColumnsTemplate } from "./utils"

export type TableContainerProps = BlockProps & {
  columns: number
  minColumnWidths?: (number | undefined | "auto")[]
  maxColumnWidths?: (number | undefined | "auto")[]
  defaultMinColumnWidth?: number
  defaultMaxColumnWidth?: number
  horizontalSpacing?: number
  horizontalPadding?: number
  horizontalMargin?: number
  className?: string
}

const TableContainer = styled(Block).attrs({
  as: "ul",
  role: "table",
})<TableContainerProps>`
  width: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: ${getColumnsTemplate};

  [role="cell"],
  [role="columnheader"] {
    padding-left: ${props => props.horizontalSpacing ?? 8}px;
    padding-right: ${props => props.horizontalSpacing ?? 8}px;

    &:last-child {
      padding-right: ${props =>
        props.horizontalPadding ??
        (props.horizontalSpacing !== undefined
          ? props.horizontalSpacing * 2
          : 16)}px;
      margin-right: ${props => props.horizontalMargin ?? 0}px;
    }

    &:first-child {
      padding-left: ${props =>
        props.horizontalPadding ??
        (props.horizontalSpacing !== undefined
          ? props.horizontalSpacing * 2
          : 16)}px;
      margin-left: ${props => props.horizontalMargin ?? 0}px;
    }
  }
`

const TableRow = styled.li.attrs({ role: "row" })`
  display: contents;
`

const TableCellContainer = styled(Block)<{
  $variant: "header" | "body"
  $removeStickyHeader?: boolean
}>`
  display: flex;
  align-items: center;
  padding-top: ${props =>
    props.paddingTop ? undefined : props.$variant === "body" ? "16px" : "4px"};
  padding-bottom: ${props =>
    props.paddingBottom
      ? undefined
      : props.$variant === "body"
      ? "16px"
      : "4px"};
  border-top: ${props =>
    props.$variant === "body"
      ? `1px solid ${props.theme.colors.components.border.level2}`
      : "none"};

  &[role="columnheader"] {
    z-index: 2;
    // placement issues when sticky is used in a container using overflow or flex
    // ie. Tooltip, the header floats in the random positions on the table
    // https://stackoverflow.com/questions/45530235/positionsticky-is-not-working
    position: ${props => (props.$removeStickyHeader ? "static" : "sticky")};
    top: 0;
    border-bottom: ${props =>
      `1px solid ${props.theme.colors.components.border.level2}`};
    /* Workaround for double border issue */

    margin-top: ${props =>
      props.marginTop
        ? undefined // TODO: clean this up. Allows Block styleFn to set value from props
        : "-1px"};
  }
`

type RenderTableHeaderProps = {
  header: React.ReactNode
  Header: typeof TableHeaderCell
  index: number
  removeStickyHeader?: boolean
}

type RenderTableHeader = (props: RenderTableHeaderProps) => React.ReactNode

export type TableCellProps = BlockProps & {
  children?: React.ReactNode
  className?: string
}

type TableHeaderCellProps = TableCellProps & {
  removeStickyHeader?: boolean
}

const TableHeaderCell = ({
  children,
  className,
  removeStickyHeader,
  ...blockProps
}: TableHeaderCellProps) => {
  return (
    <TableCellContainer
      className={classNames("text-secondary", className)}
      {...blockProps}
      $removeStickyHeader={removeStickyHeader}
      $variant="header"
      role="columnheader"
    >
      <Overflow>{children}</Overflow>
    </TableCellContainer>
  )
}
const TableCell = ({ children, className, ...blockProps }: TableCellProps) => {
  return (
    <TableCellContainer
      className={className}
      {...blockProps}
      $variant="body"
      role="cell"
    >
      <Overflow>{children}</Overflow>
    </TableCellContainer>
  )
}

export type TableProps = Omit<TableContainerProps, "columns"> & {
  headers: React.ReactNode[]
  children: React.ReactNode
  renderHeader?: RenderTableHeader
  removeStickyHeader?: boolean
}

const TableBase = ({
  headers,
  children,
  renderHeader,
  className,
  removeStickyHeader,
  ...containerProps
}: TableProps) => {
  return (
    <TableContainer
      className={className}
      columns={headers.length}
      {...containerProps}
    >
      <TableRow>
        {headers.map((columnHeader, index) =>
          renderHeader ? (
            renderHeader({
              Header: TableHeaderCell,
              header: columnHeader,
              index,
              removeStickyHeader,
            })
          ) : (
            <TableHeaderCell
              key={index}
              removeStickyHeader={removeStickyHeader}
            >
              {columnHeader}
            </TableHeaderCell>
          ),
        )}
      </TableRow>
      {children}
    </TableContainer>
  )
}

export const Table = Object.assign(TableBase, {
  Row: TableRow,
  Cell: TableCell,
  Skeleton: TableSkeleton,
  RowSkeleton: TableRowSkeleton,
})
