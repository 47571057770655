import styled from "styled-components"
import AssetSearchSortDropdown from "../AssetSearchSortDropdown.react"

export const PhoenixAssetSearchSortDropdown = styled(AssetSearchSortDropdown)<{
  fullWidth?: boolean
}>`
  width: ${props => (props.fullWidth ? "100%" : "auto")};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  padding-left: 16px;

  i {
    color: ${props => props.theme.colors.text.primary};
  }

  &:hover {
    i[aria-label="Show more"] {
      color: ${props => props.theme.colors.text.primary};
    }
  }
`
