import React from "react"
import { Icon, useIsLessThanLg } from "@opensea/ui-kit"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"

type ToggleFilterButtonProps = {
  onToggle?: () => void
  numFiltersApplied: number
}

export function ToggleFilterButton({
  onToggle,
  numFiltersApplied,
}: ToggleFilterButtonProps) {
  const t = useTranslate("phoenix")
  const isLessThanLg = useIsLessThanLg()
  const {
    toggleSidebar,
    sidebarOpen,
    isMobileFilterDrawerOpen,
    setIsMobileFilterDrawerOpen,
  } = useAccountOrCollectionPageContext()
  const toggleSideBar = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    toggleSidebar(!sidebarOpen)
    onToggle?.()
  }
  const toggleMobileSidebar = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setIsMobileFilterDrawerOpen(!isMobileFilterDrawerOpen)
    onToggle?.()
  }
  const toggleableElementOpen = isLessThanLg
    ? isMobileFilterDrawerOpen
    : sidebarOpen

  const icon = <Icon className="text-primary" value="filter_list" />

  return (
    <>
      <Button
        aria-expanded={toggleableElementOpen ? "true" : "false"}
        aria-label={
          toggleableElementOpen
            ? t("search.toggles.close", "Close")
            : t("search.toggles.open", "Open")
        }
        data-testid="filter-toggle"
        icon={icon}
        variant="secondary"
        onClick={isLessThanLg ? toggleMobileSidebar : toggleSideBar}
      >
        {numFiltersApplied ? numFiltersApplied : ""}
      </Button>
    </>
  )
}
